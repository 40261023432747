import { useMutation, useQuery } from "@tanstack/react-query";
import { autoSignIn, confirmSignUp, fetchAuthSession, signIn, signOut, signUp } from 'aws-amplify/auth';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setUserId, setUserJwt } from "../redux/state-slices/globalSlice";
import { ConfirmSignup, LoginData, SignupData } from "../types/User";

/**
 * ## Will redirect to /login in case user is not logged in
 * @returns User Session Info
 */
export const useQueryUserSession = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ['userSession'],
    queryFn: async () => {
      console.group('Auth - getCurrentUser')
      try {
        const authSession = await fetchAuthSession({forceRefresh: true});

        dispatch(setUserId(authSession.userSub));
        dispatch(setUserJwt(authSession.tokens?.idToken?.toString()));
        return { userId: authSession.userSub };
      } catch (error) {
        console.log((error as any).message);
        navigate('/login');
      }
      console.groupEnd();
      return null;
    },
    staleTime: 1,
  });
};

export const useQueryUserAutoLogin = () => {
  return useMutation({
    mutationFn: async () => {
      console.group('Auth - AutoLogin');
      try {
        const resp = await autoSignIn();
        return resp;
      } catch (error) {
        console.log((error as any).message);
      }
      console.groupEnd();
      return null;
    },
  });
};

export const useMutationUserSignup = () => {
  return useMutation({
    mutationFn: async (signupData: SignupData) => {
      console.group('useMutationUserSignup - signup');
      try {
        const signupResponse = await signUp({
          password: signupData.password,
          username: signupData.email,
          options: {
            userAttributes: {},
            autoSignIn: {
              authFlowType: "USER_SRP_AUTH",
            }
          }
        });


        return signupResponse;
      } catch (error) {
        console.log((error as any).message);
      }
      console.groupEnd();
      return null;
    }
  });
};

/**
 * Will redirect to '/' in case of success
 * @returns 
 */
export const useMutationUserConfirmSignup = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (confirmSignup: ConfirmSignup) => {
      console.group('useMutationUserConfirmSignup - confirmSignUp');
      try {

        confirmSignUp({
          username: confirmSignup.email,
          confirmationCode: confirmSignup.code,
        });
        autoSignIn()
          .then(() => navigate('/', { replace: true }));

        console.groupEnd();
        return {};
      } catch (error) {
        console.log((error as any).message);
        console.groupEnd();
        return error;
      }
    }
  });
};

export const useMutationLogin = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async ({ email, password }: LoginData) => {
      console.group('useMutationLogin - Log In intent');
      localStorage.clear();
      try {
        const loginResp = await signIn({
          username: email,
          password,
        });
        console.groupEnd();
        navigate('/v1');

        return loginResp;
      } catch (error) {
        console.log((error as any).message);
        console.groupEnd();
        return error;
      }
    },
  });
};

export const useMutationLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async () => {
      console.group('useMutationLogout - Log Out intent');
      try {
        const logoutResp = await signOut();
        console.groupEnd();

        dispatch(setUserId(undefined));
        navigate('/login');

        return logoutResp;
      } catch (error) {
        console.log((error as any).message);
        console.groupEnd();
        return error;
      }
    },
  });
};