import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = translations();

i18n
  .use(initReactI18next)
  .init({
    resources,
    debug: false,
    fallbackLng: 'en',
  });


function translations() {
  return {
    en: {
      translation: {
        title: 'Finances',
        expenseName: 'Expense Name',
        expenseAmount: 'Expense Amount',
        expenseComment: 'Expense Comment',
        create: 'Create',
        login: 'Login',
        signup: 'Signup',
        confirmSignup: 'Confirm Account',
        checkYourEmail: 'Check your email, copy the code and paste it below',
        createExpense: 'Create Expense',
        createExpenseMissingRequired: 'Missing required fields',
        onlyNumbersErrorMsg: 'Only Numbers Allowed',
        totalExpensesByType: 'Total Expenses by Expense Type',
        noExpensesYet: 'No expenses yet, create one to start seeing metrics',
        expensesTimeline: 'Expenses Timeline',
        createDate: 'Expense Date',
        createPayIn: 'New Pay In',
        accountName: 'Account Name',
        payInAmount: 'Pay In Amount',
        payInDate: 'Date of Pay In',
        description: 'Description',
        today: 'Today',
        last7Days: 'Last 7 days',
        last15Days: 'last 15 days',
        last30Days: 'last 30 days',
        thisWeek: 'This Week',
        thisMonth: 'This Month',
        lastMonth: 'Last Month',
        last3Months: 'Last 3 months',
        last6Months: 'Last 6 months',
        thisYear: 'This year',
        lastYear: 'Last year',
        totalSpent: 'Total Spent',
        bankAccounts: 'Bank Accounts',
        totalExpenseByBankAccount: 'Total expenses by bank account',
        attachReceipt: 'Attach Receipt',
        attach: 'Attach',
        dragNDrop: 'Drag & Drop',
        onlyImagesAndPdfs: 'Only Images and Pdfs',
        email: 'Email',
        password: 'Password',
        passwordConfirm: 'Confirm Password',
        verificationCode: 'Verification Code',
        passwordsAreNotEqual: 'Passwords are not the same',
        emailRequired: 'Email is required',
        passwordRequired: 'Password is required',
        confirmCodeRequired: 'Confirmation code is required',
        onlyNumbers: 'Only numbers',
        passwordConfirmRequired: 'Password confimation is required',
        atLeast1UppercaseChar: 'Password must have at least 1 uppercase character',
        atLeast1Number: 'Password must have at least 1 number',
        atLeast1SpecialChar: 'Password must have at least 1 special character: !#$%@',
        atLeast8Chars: 'Password must be at least 8 characters long',
        amountRequired: 'Invalid Expense Amount',
        location: 'Location',
        amount: 'Amount',
        payInsAndExpensesByDay: 'Pay Ins and Expenses by day',
        noPayInsAndExpensesYet: 'No Pay Ins and Expenses yet',
        expensesCount: 'Expenses Count',
        payInsCount: 'Pay Ins count',
        date: 'Date',
        payInsTimeline: 'Pay Ins Timeline',
        reminders: 'Reminders',
        reminderMessage: 'Reminder',
        reminderActions: 'Actions',
        RECURRING: 'RECURRING',
        UPCOMMING: 'UPCOMMING',
        COMPLETE: 'COMPLETE',
        FORGOTTEN: 'FORGOTTEN',
        remindersRowsPerPage: 'Rows per page',
        selected: 'selected',
        ReminderMessage: 'Reminder Message',
        ReminderDate: 'Reminder Date',
        CreateReminder: 'Create Reminder',
        submitReminderButton: 'CREATE',
        createReminderButton: ' New Reminder',
        deleteReminderButton: 'Delete',
        reminderDone: 'Done',
        rescheduleReminder: 'Reschedule next month'
      }
    },
    es: {
      translation: {
        title: 'Finanzas',
        expenseName: 'Nombre del Gasto',
        expenseAmount: 'Cuantía del Gasto',
        expenseComment: 'Comentario',
        create: 'Crear',
        login: 'Login',
        signup: 'Crear Cuenta',
        confirmSignup: 'Confirmar Cuenta',
        checkYourEmail: 'Revisa tu correo, copia el código y pégalo abajo',
        createExpense: 'Nuevo Gasto',
        createExpenseMissingRequired: 'Faltan campos obligatorios',
        onlyNumbersErrorMsg: 'Ese campo sólo permite números',
        totalExpensesByType: 'Total gastos por Tipo de Gasto',
        noExpensesYet: 'No hay Gastos aún, crea uno para empezar a ver métricas',
        expensesTimeline: 'Línea de tiempo de Gastos',
        createDate: 'Fecha del Gasto',
        createPayIn: 'Dinero Entrante',
        accountName: 'Nombre de cuenta',
        payInAmount: 'Cantidad de dinero entrante',
        payInDate: 'Fecha de dinero entrante',
        description: 'Descripción',
        today: 'Hoy',
        last7Days: 'Últimos 7 días',
        last15Days: 'Últimos 15 días',
        last30Days: 'Últimos 30 días',
        thisWeek: 'Ésta Semana',
        thisMonth: 'Éste Mes',
        lastMonth: 'El mes pasado',
        last3Months: 'Últimos 3 meses',
        last6Months: 'Últimos 6 meses',
        thisYear: 'Éste año',
        lastYear: 'El año pasado',
        totalSpent: 'Total Gastado',
        bankAccounts: 'Cuentas Bancarias',
        totalExpenseByBankAccount: 'Gastos totales por cuenta',
        attachReceipt: 'Adjuntar Recibo',
        attach: 'Adjuntar',
        dragNDrop: 'Arrastrar y Soltar',
        onlyImagesAndPdfs: 'Sólo Imágenes y Pdfs',
        email: 'Correo Electrónico',
        password: 'Contraseña',
        passwordConfirm: 'Confirmar Contraseña',
        verificationCode: 'Código de verificación',
        passwordsAreNotEqual: 'Contraseñas no son iguales',
        emailRequired: 'Correo electrónico es obligatorio',
        passwordRequired: 'Contraseña es obligatoria',
        passwordConfirmRequired: 'Confirmación de contraseña es obligatorio',
        confirmCodeRequired: 'Código de confirmación es obligatorio',
        onlyNumbers: 'Sólo números',
        atLeast1UppercaseChar: 'Contraseña debe tener al menos 1 letra mayúscula',
        atLeast1Number: 'Contraseña debe tener al menos 1 número',
        atLeast1SpecialChar: 'Contraseña debe tener al menos 1 caracter especial: !#$%@',
        atLeast8Chars: 'Contraseña debe tener al menos 8 caracteres',
        amountRequired: 'Cuantía del gasto inválida',
        location: 'Ubicación',
        amount: 'Monto',
        payInsAndExpensesByDay: 'Dinero entrante y gastos por día',
        noPayInsAndExpensesYet: 'Aún no hay dinero entrante ni gastos por día',
        expensesCount: 'Cantidad de gastos',
        payInsCount: 'Cantidad de transacciones de dinero entrante',
        date: 'Fecha',
        payInsTimeline: 'Línea de tiempo de Ingresos',
        reminders: 'Recordatorios',
        reminderMessage: 'Recordatorio',
        reminderActions: 'Acciones',
        RECURRING: 'RECURRENTE',
        UPCOMMING: 'PRÓXIMO',
        COMPLETE: 'TERMINADO',
        FORGOTTEN: 'OLVIDADO',
        remindersRowsPerPage: 'Filas por página',
        selected: 'seleccionado',
        ReminderMessage: 'Recordatorio',
        ReminderDate: 'Fecha del Recordatorio',
        CreateReminder: 'Crear Recordatorio',
        submitReminderButton: 'CREAR',
        createReminderButton: 'Nuevo Recordatorio',
        deleteReminderButton: 'Eliminar',
        reminderDone: 'Terminado',
        rescheduleReminder: 'Reprogramar para próximo mes'
      }
    }
  };
}

export default i18n;