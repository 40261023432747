import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Container, Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryUserBankAccounts } from '../hooks/bank-hooks';
import { getCurrencyVerboseFormatter } from '../util/formatting';

const currencyVerbose = getCurrencyVerboseFormatter();

export function BankAccountsView() {
  const { t } = useTranslation();
  const { data: userBankAccounts = [] } = useQueryUserBankAccounts();

  return (
    <Container maxWidth={false} sx={{ mt: '20px', mb: '20px' }}>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
          <AccountBalanceIcon color='primary' />&nbsp;
          <Typography variant="h6"> {t('bankAccounts')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {userBankAccounts.map(bank => [
              <ListItem alignItems='flex-start' key={bank.accountName}>
                <ListItemText
                  secondary={(
                    <Typography variant='body2'>{bank.accountName}:&nbsp;{currencyVerbose.format(bank.balance)}</Typography>
                  )}
                />
              </ListItem>,
              <Divider variant="inset" component="li" key={bank.accountName + 'li-item-divider'} />
            ]
            )}


          </List>
        </AccordionDetails>
      </Accordion>

    </Container>
  );
}