
export type StringOrNumber = string | number;

export enum DateFormatByPurpose {
    Timeline = 'MMM D, YYYY, HH:mm',
    DateInput = 'YYYY-MM-DDTHH:mm:ss',
    Epoch = 'x',
    DateRangeWidget = 'YYYY-MM-DD',
    ChartAxis = 'MMM, DD',
    Reminder = 'MMM D, YYYY, hh:mmA'
}