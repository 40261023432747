import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { IconButton, LinearProgress, Paper, TextField, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutationCreateUserReminder } from '../hooks/user-reminder-hooks';
import { AxiosResp } from '../rest-api/request-util';
import { CreateUserReminderRequestBody } from '../types/User';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.tz.setDefault("America/Bogota");

const emptyReminder: CreateUserReminderRequestBody = {
  reminder: '',
  reminderDate: Date.now(),
};

// To do: Change the name of the file and the function to createRemindersPopOver*****
export default function CreateReminderPopover() {
  const { t } = useTranslation();

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<EventTarget & HTMLButtonElement | null>(null);

  const [userReminder, setUserReminder] = useState(emptyReminder);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [errorMsg, setShowError] = useState<string | undefined>();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const queryClient = useQueryClient();
  const createUserReminderMutation = useMutationCreateUserReminder(queryClient);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setShowLoader(true);
    createUserReminderMutation.mutateAsync(userReminder).then((e) => {
      setShowLoader(false);
      setAnchorEl(null)
    })
      .catch((resp: AxiosResp | any) => {
        const err = typeof (resp.data) == "string" ? resp.data : JSON.stringify(resp.data);
        setShowLoader(false);
        setShowError(err);
      });
  };

  return (
    <div>
      <IconButton onClick={handleClick} sx={{ mr: '7px' }}>
        <Tooltip title={t("createReminderButton")}>
        <AddRoundedIcon />

        </Tooltip>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {showLoader && <LinearProgress color='secondary' />}
        <Paper elevation={3} sx={{ p: '16px' }}>
          <Typography sx={{ p: 2 }}>{t("CreateReminder")} </Typography>
          <TextField
            type='datetime-local'
            label={t('ReminderDate')}
            value={dayjs(userReminder.reminderDate).format('YYYY-MM-DDTHH:mm:ss')}
            margin='normal'
            fullWidth
            onChange={(e) => setUserReminder({ ...userReminder, reminderDate: new Date(e.target.value).getTime() + (Math.ceil(Math.random() * 999)) })}
            InputLabelProps={{ shrink: true }}
          />
          <br />
          <TextField type='text'
            label={t('ReminderMessage')}
            margin='normal'
            value={userReminder.reminder}
            fullWidth
            onChange={(e) => setUserReminder({ ...userReminder, reminder: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
          <br />
          <Button variant="contained" color='primary' type="submit" sx={{ mt: 2 }} onClick={handleSubmit}>
            {t("submitReminderButton")}
          </Button>
        </Paper>
      </Popover>
    </div>
  );
}