import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { FC } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n';
import './index.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import Login from './routes/Login';
import Public from './routes/Public';

const queryClient = new QueryClient();

const wrapWithProviders = (Comp: FC): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Comp />
      </Provider>
    </QueryClientProvider>
  );
};

const router = createBrowserRouter([
  {path: '/', element: wrapWithProviders(Public)},
  {path: '/v1', element: wrapWithProviders(App)},
  {path: '/login', element: wrapWithProviders(Login)}
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
