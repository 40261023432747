// Import necessary dependencies
import { AppBar, Button, Container, CssBaseline, Divider, Grid, Link, Toolbar, Typography } from '@mui/material';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import React from 'react';
import { dark } from '../config/themeConfig';
import { useNavigate } from 'react-router-dom';

const illuxaAnimation = keyframes`
  0%, 100% {
    filter: brightness(80%);
  }
  50% {
    filter: brightness(140%);
  }
`;

const IlluxaText = styled('h1')({
  fontSize: '8rem',
  fontWeight: 'bold',
  textAlign: 'center',
  width: '90%',
  height: '50%',
  animation: `${illuxaAnimation} 3s infinite ease-in-out`,
});

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
  }
};

const Public: React.FC = () => {
  const theme = createTheme(dark);
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <div style={{ flexGrow: 1 }} />
          <Button color="inherit" onClick={() => navigate('/login', { replace: false })}>
            Login
          </Button>
        </Toolbar>
      </AppBar>

      <Container sx={classes.container}>
        <IlluxaText>
          ILLUXA
        </IlluxaText>
      </Container>

      <Divider />

      <footer style={{ marginTop: '10px' }}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">

          <Grid item xs={12} sm={6}>
            <Typography variant="body2" align="left" color="textSecondary" style={{marginLeft: 10}}>
              Contact:&nbsp;<Link href="mailto:illuxa.manage@gmail.com">illuxa.manage@gmail.com</Link>
              <br />
              Person: Esteban Rincon / Diego Rincon
              <br />
              Phone: +573004832855 / +573108836381
              <br />
              Address: km 12 Anillo vial, Laguna Club, Roble 6 2-B, Cartagena, Bolivar, 130007
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} style={{}}>
            <Typography variant="body2" align="right" color="textSecondary">
              © {new Date().getFullYear()} ILLUXA SAS. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </footer>

    </ThemeProvider>
  );
};

export default Public;
