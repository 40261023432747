import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { DateFormatByPurpose } from "../types/global";
// import 'dayjs/locale/es';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
// TODO: 
// dayjs.locale('es');

export const DEFAULT_LOCALE = 'es-CO';
export const DEFAULT_CURRENCY = 'COP';

/**
 * When called with `.format(125300)`,
 * the output would be: `-$1.25M`
 * 
 * @param locale Default: `es-CO`
 * @param currency Default: `COP`
 * @returns Currency Number Formatter. 
 */
export const getCurrencyCompactFormatter = (locale: string = DEFAULT_LOCALE, currency: string = DEFAULT_CURRENCY) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 2,
    notation: 'compact',
    minimumFractionDigits: 0
  });
};

/**
 * When called with `.format(-1250800)`, 
 * the output would be: `-$ 1.250.800,00`
 * 
 * @param locale Default: `es-CO`
 * @param currency Default: `COP`
 * @returns Currency Number Formatter. 
 */
export const getCurrencyVerboseFormatter = (locale: string = DEFAULT_LOCALE, currency: string = DEFAULT_CURRENCY) => {
  return new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0,
  });
};

const DEFAULT_TZ = "America/Bogota";

export const epochToLocalTzFormatted = <T>(
  epoch: number,
  formatOutput: DateFormatByPurpose = DateFormatByPurpose.Epoch,
  relative: boolean = false,

): T => {

  const dateDayjs = dayjs.utc(epoch).tz(DEFAULT_TZ);

  if (relative) return dateDayjs.fromNow() as T;

  const formattedResult = dateDayjs.format(formatOutput);

  if (formatOutput === DateFormatByPurpose.Epoch) return parseInt(formattedResult) as T;

  return formattedResult as T;
};

export const dateStringToEpoch = (date: string): number => {
  return parseInt(dayjs(date).format(DateFormatByPurpose.Epoch));
};