import CloseIcon from '@mui/icons-material/Close';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, IconButton, LinearProgress, Modal, Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useMutationDeleteUserPayIns } from '../hooks/pay-in-hooks';
import { UserPayIn } from "../types/User";
import { DateFormatByPurpose } from '../types/global';
import { epochToLocalTzFormatted, getCurrencyVerboseFormatter } from '../util/formatting';


const currency = getCurrencyVerboseFormatter();

export type PayInsTimelineProps = {
    userPayIns: Required<UserPayIn>[];
}

export default function PayInsTimeline(props: PayInsTimelineProps) {
    const theme = useTheme();
    const { t } = useTranslation();
    const [showPayInsDetails, setshowPayInsDetails] = useState<boolean>(false);
    const [selectedPayIn, setSelectedPayIn] = useState<UserPayIn>();
    const queryClient = useQueryClient();
    const useDeleteUserPayInMutation = useMutationDeleteUserPayIns(queryClient);

    return (
        <>
            {useDeleteUserPayInMutation.isPending && <LinearProgress color="secondary" />}

            <Modal open={showPayInsDetails && typeof (selectedPayIn) === "object"} onClose={setshowPayInsDetails}
                sx={{ outline: 'none' }}
            >
                <Grid container justifyContent="center" sx={{ mt: '30px', outline: 'none' }}>
                    <Grid item xs={11} md={8} lg={5}>
                        <Card>

                            <CardHeader
                                action={<IconButton onClick={() => setshowPayInsDetails(false)}>
                                    <CloseIcon />
                                </IconButton>}
                                title={selectedPayIn?.accountName}

                                subheader={epochToLocalTzFormatted<string>(selectedPayIn?.createDate ?? 0, DateFormatByPurpose.Timeline )} />
                                
                            <CardContent>
                                <Table size='small'>
                                    <TableBody>
                                        {Object.entries(selectedPayIn ?? {}).map(([k, v]) => {
                                            if (/(acountName|createDate|payInDate|userId)/.test(k)) return null;

                                            return (
                                                <TableRow key={`${k}_${v}`}>
                                                    <TableCell variant="head">{t(k)}</TableCell>
                                                    <TableCell>
                                                        {k === 'amount' ? currency.format(v as number) : v ?? ' - '}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </CardContent>
                            <Divider sx={{ mt: 2 }} />
                            <CardActions>
                                <Grid container spacing={2} justifyContent={'center'}>
                                    <Grid item>
                                        <Button disabled={useDeleteUserPayInMutation.isPending} size="small" variant='text' color='error'
                                            onClick={() => {
                                                if (selectedPayIn?.createDate) {
                                                    useDeleteUserPayInMutation.mutateAsync(selectedPayIn.createDate)
                                                        .then(r => {
                                                            setshowPayInsDetails(false);
                                                        })
                                                        .catch(console.log);
                                                } else {
                                                    console.log('selectedPayIn did not have a `payInDate` so could not perform DELETE, see expense obj:', selectedPayIn);
                                                }
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>

            </Modal>
            <Timeline position='left' style={{ overflowX: "hidden" }}>
                {
                    (props.userPayIns ?? []).sort((a, b) => b.createDate - a.createDate).map((e, i) => {
                        const date = epochToLocalTzFormatted<string>(e.createDate, DateFormatByPurpose.Timeline);
                        const dotColor = i === 0 ? theme.palette.primary.light : theme.palette.secondary.main;
                        const dotScale = i === 0 ? 'scale(2)' : 'scale(1)';
                        return (

                            <TimelineItem key={`${e.accountName}-${i}`} className='timeline-item'
                                onClick={() => {
                                    setSelectedPayIn(e);
                                    setshowPayInsDetails(true);
                                }}
                            >
                                <TimelineOppositeContent color={theme.palette.primary.contrastText} sx={{ fontSize: '10px' }}>
                                    {date}
                                </TimelineOppositeContent>


                                <TimelineSeparator>
                                    <TimelineDot sx={{ bgcolor: dotColor, transform: dotScale }} />
                                    <TimelineConnector sx={{ bgcolor: theme.palette.primary.light }} />
                                </TimelineSeparator>

                                <TimelineContent sx={{ color: theme.palette.primary.contrastText, fontSize: '11px', width: '100px', marginRight: 2, textAlign: 'right', padding: 0, textIndent: -20 }}>
                                    {e.accountName} {currency.format(e.amount)}
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })}
            </Timeline>
        </>
    );
} 