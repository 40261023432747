import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { __financesApiClient } from "../rest-api/client-instances-application-scoped";
import { CreateUserReminderRequestBody, } from "../types/User";


export const useQueryUserReminders = () => {
    return useQuery({
        queryKey: ['userReminders'],
        queryFn: async () => __financesApiClient.getUserReminders(),
        staleTime: 1,
    });
}

export const useMutationCreateUserReminder = (queryClient: QueryClient) => {
    return useMutation({
      mutationFn: async (reminder: CreateUserReminderRequestBody) => __financesApiClient.createUserReminder(reminder),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['userReminders'] });
      },
    });
  };

  export const useMutationDeleteUserReminder = (queryClient: QueryClient) => {
  return useMutation({
    mutationFn: async (reminderDateEpoch: number) => __financesApiClient.deleteUserReminder(reminderDateEpoch),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userReminders'] });
    },
  });
};

export const useMutationRescheduleUserReminder = (queryClient: QueryClient) => {
  return useMutation({
    mutationFn: async (reminderDateEpoch: number) => __financesApiClient.rescheduleUserReminder(reminderDateEpoch),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userReminders'] });
    },
  });
};
