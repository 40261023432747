import { QueryClient, keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { __financesApiClient } from '../rest-api/client-instances-application-scoped';
import { UserExpense } from "../types/User";
import { StringOrNumber } from "../types/global";

export const useQueryUserExpenses = (dateRange: [StringOrNumber, StringOrNumber]) => {
  return useQuery({
    queryKey: ['userExpenses', dateRange],
    queryFn: async () => __financesApiClient.getExpenses(dateRange),
    staleTime: 1,
    placeholderData: keepPreviousData,
  });
};

export const useQueryUserExpenseTypes = () => {
  return useQuery({
    queryKey: ['userExpenseTypes'],
    queryFn: async () => __financesApiClient.getExpenseTypes(),
    staleTime: 1,
  });
};

/**
 * Creates a User Expense, once the HTTP request is successful,
 * a refetch will be triggered to update the `userExpenses`
 */
export const useMutationCreateUserExpense = (queryClient: QueryClient) => {
  return useMutation({
    mutationFn: async (expense: UserExpense) => __financesApiClient.createExpense(expense),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userExpenses'] });
      queryClient.invalidateQueries({ queryKey: ['userBankAccounts'] });
    },
  });
};

export const useMutationDeleteUserExpense = (queryClient: QueryClient) => {
  return useMutation({
    mutationFn: async (createdAtEpoch: number) => __financesApiClient.deleteExpense(createdAtEpoch),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userExpenses'] });
      queryClient.invalidateQueries({ queryKey: ['userBankAccounts'] });
    },
  });
};